import { PeopleControllerService, PeopleVo } from '@/__generated__/FrontApi';
import IcoLink from '@/assets/img/icons/ico_share_black@2x.png';
import BgAfter from '@/assets/img/tmp/people/people_detail_after@2x.png';
import BgBefore from '@/assets/img/tmp/people/people_detail_before@2x.png';
import AccordionComponent, { AccordionItem } from '@/components/Accordion';
import { Active } from '@/components/Article/types';
import Button from '@/components/Button';
import Container from '@/components/Container';
import Image from '@/components/Image';
import LinkSafe from '@/components/LinkSafe';
import Section from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import { Tit } from '@/components/Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { PageProps, navigate } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/scrollbar/scrollbar.min.css';
import 'swiper/swiper.min.css';

const SectionTop = styled(Section)`
  position: relative;
  padding-bottom: 0 !important;

  figure {
    position: relative;

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
    }

    &::before {
      width: 400px;
      height: 200px;
      background: url(${BgBefore}) center no-repeat;
      background-size: cover;
      right: 100%;
    }
    &::after {
      width: 200px;
      height: 400px;
      background: url(${BgAfter}) center no-repeat;
      background-size: cover;
      left: 100%;
    }
  }

  ${breakpoint(1160)} {
    figure {
      &::before {
        width: 34.48vw;
        height: 17.24vw;
      }
      &::after {
        width: 17.24vw;
        height: 34.48vw;
      }
    }
  }
  ${breakpoint(`tablet`)} {
    ${SectionHeader} {
      padding-bottom: 72px;
    }

    figure {
      &::before {
        width: 31.25vw;
        height: 15.63vw;
      }
      &::after {
        width: 15.63vw;
        height: 31.25vw;
      }
    }
  }
`;

const AccordionBody = styled.div`
  padding: 32px 0;
  font-size: 16px;

  figure,
  .video-container {
    max-width: 700px;
    width: 100%;
  }
  .video-wrap {
    width: 100%;
    position: relative;
    padding-top: 56.25%;

    .video {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }
  }

  ${breakpoint(`tablet`)} {
    figure,
    .video-container {
      max-width: 100%;
    }
  }
`;
const SectionDetail = styled(Section)`
  padding-top: 280px;

  article {
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 0;
    }
    h2 {
      padding-bottom: 32px;
    }
  }

  .accordion-opener {
    display: block;
    width: 100%;
    padding: 24px;
    cursor: pointer;
    font-weight: bold;
    font-size: 20px;
    position: relative;
    line-height: 1.6;

    &.link-item::before {
      content: '';
      display: block;
      width: 32px;
      height: 32px;
      background: #f8f9fa url(${IcoLink}) no-repeat center;
      background-size: 24px;
      border-radius: 100%;
      position: absolute;
      right: 24px;
      top: 50%;
      -webkit-transform: translate(0, -50%);
      -ms-transform: translate(0, -50%);
      transform: translate(0, -50%);
      z-index: -1;
    }

    &.plain-item {
      cursor: default;
    }
  }

  ${breakpoint(`tablet`)} {
    padding-top: 72px;

    article {
      margin-bottom: 72px;
      h2 {
        padding-bottom: 24px;
      }
    }
  }

  ${breakpoint(`mobile`)} {
    .accordion-opener {
      &.link-item::before {
        right: 0;
      }
    }
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  text-align: right;

  ${breakpoint(`tablet`)} {
    text-align: center;
  }
`;

const selectContent = (row: Active, activeId: string, hash?: string) => {
  if (row.contentCategoryName === `링크`) {
    let linkHash = ``;
    const index = activeId.split(`-`);
    if (!!index[1] && parseInt(index[1], 10) > 4) {
      linkHash = `${index[0]}-${parseInt(index[1], 10) - 4}`;
    } else {
      linkHash = hash || ``;
    }

    return (
      <li id={activeId}>
        <LinkSafe
          target="_blank"
          key={row.activeIndexNumber}
          to={row.relUrl}
          onClick={() => {
            if (linkHash) {
              window.location.hash = linkHash;
            }
          }}
          className="accordion-opener link-item"
        >
          <Tit size="s4-1">{row.activeName}</Tit>
        </LinkSafe>
      </li>
    );
  }
  if (row.contentCategoryName === `이미지`) {
    return (
      <AccordionItem
        id={activeId}
        key={row.activeIndexNumber}
        toggleKey={row.activeIndexNumber}
        renderToggle={(onClick) => (
          <div onClick={onClick} className="accordion-opener">
            <Tit size="s4-1">{row.activeName}</Tit>
          </div>
        )}
      >
        <AccordionBody>
          <figure>
            <Image pcSrc={row.attGrpNoDetail} mobileSrc={row.attGrpNoDetail} />
          </figure>
        </AccordionBody>
      </AccordionItem>
    );
  }
  if (row.contentCategoryName === `영상`) {
    return (
      <AccordionItem
        id={activeId}
        key={row.activeIndexNumber}
        toggleKey={row.activeIndexNumber}
        renderToggle={(onClick) => (
          <div onClick={onClick} className="accordion-opener">
            <Tit size="s4-1">{row.activeName}</Tit>
          </div>
        )}
      >
        <AccordionBody>
          <div className="video-container">
            <div className="video-wrap">
              <iframe
                className="video"
                src={row.videoUrl}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </div>
        </AccordionBody>
      </AccordionItem>
    );
  }
  return (
    <li id={activeId}>
      <div key={row.activeIndexNumber} className="accordion-opener plain-item">
        <Tit size="s4-1">{row.activeName}</Tit>
      </div>
    </li>
  );
};

const PeopleDetail: React.FC<PageProps> = ({
  location,
  params: { relUrl },
}) => {
  const locationEdit = location;
  const parts = location.pathname.split(`/`);
  const last = parts[parts.length - 1];

  locationEdit.pathname = location?.pathname.substr(
    0,
    location.pathname.length - (last.length + 1),
  );

  const [people, setPeople] = useState([]);

  const loadPeopleDetail = useCallback(async () => {
    try {
      const {
        data: people,
      } = await PeopleControllerService.getPeopleAllUsingGet();
      setPeople(
        people.filter(
          (person: PeopleVo) => person.relUrl?.slice(1) === relUrl,
        )[0],
      );
    } catch (e) {
      console.error(e);
    }
  }, [relUrl]);

  useEffect(() => {
    loadPeopleDetail();
  }, [loadPeopleDetail]);

  return (
    <LayoutWithTitle
      location={locationEdit}
      title="유니세프와 사람들"
      description="for every child, people"
    >
      <SectionTop className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1-5" color="sky" weight="normal">
                {`${people.name} ${people.role}`}
              </Tit>
            </h2>

            <p className="header-dec">{people.detailContent}</p>
          </SectionHeader>

          <figure>
            <Image
              pcSrc={people.attGrpNoDetail}
              mobileSrc={people.attGrpNoDetail}
            />
          </figure>
        </Container>
      </SectionTop>
      <SectionDetail id="appointment">
        <Container>
          <article id="active">
            <header>
              <h2>
                <Tit size="s2">
                  {people.role === `특별대표`
                    ? `${people.role} 임명`
                    : `친선대사 임명`}
                </Tit>
              </h2>
            </header>
            <AccordionComponent initialKey="1">
              {people.active?.map((item, index) => (
                <>
                  {item.activeCategoryName === `임명` && (
                    <>
                      {selectContent(item, `appoint-${index}`, `appointment`)}
                    </>
                  )}
                </>
              ))}
            </AccordionComponent>
          </article>

          <article>
            <header>
              <h2>
                <Tit size="s2">주요 활동</Tit>
              </h2>
            </header>

            <AccordionComponent initialKey="1">
              {people.active?.map((item, index) => (
                <>
                  {item.activeCategoryName === `주요활동` && (
                    <>{selectContent(item, `active-${index}`, `active`)}</>
                  )}
                </>
              ))}
            </AccordionComponent>
          </article>

          <ButtonContainer>
            <Button
              color="grey"
              size="sm"
              width="150"
              onClick={() => navigate(`/about-us/people`)}
            >
              이전
            </Button>
          </ButtonContainer>
        </Container>
      </SectionDetail>
    </LayoutWithTitle>
  );
};

export default PeopleDetail;
