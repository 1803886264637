import Button from './Button';
import { breakpoint } from '@/helpers/BreakpointHelper';
import styled from 'styled-components';

const SectionHeader = styled.header`
  padding-bottom: 96px;

  &.t2 {
    padding-bottom: 64px;
  }

  &.with-desc {
    .flex {
      margin-bottom: 32px;
    }
  }

  .flex {
    justify-content: space-between;
    align-items: flex-end;
  }
  .flex-middle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    a {
      font-weight: bold;
      top: 0;
    }
  }

  p:not(.header-dec) {
    line-height: 1.75;
    letter-spacing: -0.8px;
    color: #2d2926;
  }

  ${breakpoint(`tablet`)} {
    padding-bottom: 72px;

    &.t2 {
      padding-bottom: 48px;
    }
    &.with-desc {
      .flex {
        margin-bottom: 24px;
      }

      .flex-wrap {
        h2,
        .col-btn {
          width: 100%;
          max-width: 100%;
        }
        .col-btn {
          margin-top: 24px;
          .flex {
            & > li {
              width: 100%;
              margin-bottom: 16px;

              &:last-child {
                margin-bottom: 0;
              }

              ${Button} {
                margin-top: 0;
              }
            }
          }
        }
      }
    }

    ${Button} {
      max-width: 100%;
      margin-top: 72px;
    }
  }

  ${breakpoint(640)} {
    padding-bottom: 48px;
  }

  .flex-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    h2 {
      width: 50%;
    }
    .col-btn {
      width: 50%;
      max-width: 544px;
      .flex {
        align-items: stretch;
        margin-bottom: 0;
        & > li {
          width: 50%;
        }
      }
    }
  }
`;
export default SectionHeader;
